import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252,replace 0,1,2) should always be at the top of the file.
   * AUSDT  variable is busd(BUSD) and WOTX variable is wbnb (OTX,WOTX)
   */
  {
    pid: 0,
    lpSymbol: 'OTXS',
    lpAddresses: {
      5438: '0xe6e7de3f3f8093a96d0eb183d36839ca34b0e5c3',
      116699:'0xe6e7de3f3f8093a96d0eb183d36839ca34b0e5c3',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
 
  {
    pid: 1,
    lpSymbol: 'OTXS-WOTX LP',
    lpAddresses: {
      5438: '',
      116699: '0x2c67b38a195b0b75c98a2dd1448e8daab14fe12d',
    },
    token: tokens.otxs,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 2,
    lpSymbol: 'USDT-WOTX LP',
    lpAddresses: {
      5438: '',
      116699: '0xa992681e076c19dc07f3acd04ef670f394f9db5d',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  
  
    
    
  	  

]

export default farms
