import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  
   {
    sousId: 0,
    stakingToken: tokens.otxs,
    earningToken: tokens.otxs,
    contractAddress: {
      5438: '',
      116699: '0x1408a5ff72f3a8220c37ab721eec4babdb92d480',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '2',
    sortOrder: 1,
    isFinished: false,
  },  
 
 
 
  
]

export default pools
