import { Nft, NftSource, NftType } from './types'

export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'

export const nftSources: NftSource = {
  [NftType.PANCAKE]: {
    address: {
      116699: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
      5438: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    },
    identifierKey: 'image',
  },
  [NftType.MIXIE]: {
    address: {
      116699: '0xa251b5EAa9E67F2Bc8b33F33e20E91552Bf85566',
      5438: '',
    },
    identifierKey: 'image',
  },
}

const Nfts: Nft[] = [
  {
    name: 'Baller',
    description: 'Absolute (lottery) baller.',
    images: {
      lg: 'baller-lg.png',
      md: 'baller-md.png',
      sm: 'baller-sm.png',
      ipfs: `${IPFS_GATEWAY}/QmeMfJk6yxYmMd41ThDpqcdEJmKXZTF9EmFeP49D15NvsF/baller.png`,
    },
    video: {
      webm: `${IPFS_GATEWAY}/QmeMfJk6yxYmMd41ThDpqcdEJmKXZTF9EmFeP49D15NvsF/baller.webm`,
      mp4: `${IPFS_GATEWAY}/QmeMfJk6yxYmMd41ThDpqcdEJmKXZTF9EmFeP49D15NvsF/baller.mp4`,
    },
    sortOrder: 999,
    identifier: 'baller',
    type: NftType.PANCAKE,
    variationId: 20,
  },
  
]

export default Nfts
