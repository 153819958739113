import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'OTXchain - The best AMM DEX on OTXchain Smart Chain (KALI)',
  description:
    'The most popular AMM on OTXchain by user count! Earn OTXS through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by OtxSwap), NFTs, and more, on a platform you can trust.',
  image: 'https://dex.otxswap.com/images/swapimg/twitter.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('OtxSwap')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('OtxSwap')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('OtxSwap')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('OtxSwap')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('OtxSwap')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('OtxSwap')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('OtxSwap')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('OtxSwap')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('OtxSwap')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('OtxSwap')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('OtxSwap')}`,
      }
    default:
      return null
  }
}
